function createURL(resourceID) {
    let url;
    if(resourceID[0]=='/'){
        url = "http://portal.azure.com/#@/resource" + resourceID + "/overview";
    }else{
        url = "http://portal.azure.com/#@/resource/" + resourceID + "/overview";
    }
    return url;
}

export default createURL;
