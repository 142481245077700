import React, {useState, useEffect, Fragment} from "react";
import _ from "lodash";
import {Grid, Icon} from "semantic-ui-react";
import "./SubEfficientInput.css";

const SubEfficientInput = (props) => {
	const [query, setQuery] = useState("");

	const setValue = (newQuery) => {
		newQuery.persist();
		const thirtyFPSInput = _.debounce(() => {
			setQuery(newQuery.target.value);
		}, 48);
		thirtyFPSInput();
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => props.updateFast(query));
		return () => clearTimeout(timeOutId);
	}, [query]);

	return (
		<Fragment>
			<input className="sub-searchbar" placeholder="Filter by Resource Name" onInput={setValue} id="promo-input"></input>
		</Fragment>
	);
};

export default SubEfficientInput;
