import React, { useState, useContext } from "react";
import { Tab } from "semantic-ui-react";
import { Grid, Container, Button, Icon, List } from "semantic-ui-react";
import "./tabspricing.css";
import { useHistory, Link } from "react-router-dom";
import AppContext from "../../../../utils/AppContext";
import { useMediaQuery } from 'react-responsive';

const PricingComponent = () => {
    const history = useHistory();
    const context = useContext(AppContext);

    const signUp = () => {
        window.location.href = "https://portal.azure.com/#create/cubesys.cloud_clarity";
    };

    const signUpAVD = () => {
        window.location.href = "https://portal.azure.com/#create/cubesys.cloud_clarity_for_avd";
    };
    const tryForFree = () => {
        context.tryLogin();
    };

    const navigate = () => {
        window.location.href = "/tag-manager";
    };

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const panes = [
        {
            menuItem: "Monthly",
            render: () => (
                <Tab.Pane attached={false}>
                    <div class="custom-pricing-grid-avd">
                        <Grid doubling columns={5} className="pricing-grid custom-grid single-mobile">
                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Starter</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>For Small Teams and Cloud Environments </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$90</span>
                                                /month
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports up to 1 subscription / 1,000 resources</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Premium</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>For Medium Sized Teams and Environments </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$250</span>
                                                /month
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports up to 5 subscriptions / 5,000 resources</p>

                                        <p>BYO Storage Account</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Enterprise</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>Large Orgs. wanting to manage their cloud environment at scale </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$500</span>
                                                /month
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>
                                        
                                        <p>Supports up to 50 subscriptions / 10,000 resources</p>

                                        <p>BYO Storage Account</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Platinum</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>For MSP's, Large Government Agencies, or Large MNC's</p>
                                        </div>

                                        <div class="price-on-request">Pricing on Request</div>
                                    </div>

                                    <div className="list-section">
                                    <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" href="mailto:info@tagmanager.app">
                                                    Get in Touch
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        
                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>
                                        
                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports <b>over</b> 50 subscriptions / 10,000 resources</p>

                                        <p>BYO Storage Account + <b>Hybrid SaaS Deployment Model</b></p>
                                    </div>
                                </div>
                            </Grid.Column>
                            
                            {/* hidden for now, obselete, may remove completely in future
                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>AVD</h4>

                                        <hr />    

                                        <div class="subtitle">
                                            <p>Azure Virtual Desktop User Consumption Cost Reporting​</p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$0.35</span>
                                                /user/month
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUpAVD}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p><Link to="feature/avd">AVD Dashboard</Link></p>

                                        <p><Link to="feature/avd">Azure Virtual Desktop (AVD) User Consumption Report</Link></p>

                                        <p><Link to="feature/avd">CSV Export</Link>CSV Export</p>
                                    </div>

                                    <div className="bottom-div">
                                        <div class="custom-button-component">
                                            <a className="ui button button__component white msp" onClick={signUpAVD}>
                                                Add to existing plan
                                            </a>
                                        </div>
                                        <p className="informativeSub">Can be purchased as an add-on or standalone plan </p>
                                    </div>
                                </div>
                            </Grid.Column>*/}
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Annually",
            render: () => (
                <Tab.Pane attached={false}>
                    <div class="custom-pricing-grid">
                        <Grid doubling columns={5} className="pricing-grid custom-grid single-mobile">
                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Starter</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>For Small Teams and Cloud Environments </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$990</span>
                                                /annum
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports up to 1 subscription / 1,000 resources</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Premium</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p class="sub">For Medium Sized Teams and Environments </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$2,750</span>
                                                /annum
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        
                                        <p class="list">
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports up to 5 subscriptions / 5,000 resources</p>

                                        <p style={{ lineHeight: "64px" }}>BYO Storage Account</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Enterprise</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>Large Orgs. wanting to manage their cloud environment at scale </p>
                                        </div>
                                        <div className="price-div">
                                            <div class="price">
                                                USD
                                                <span className="large-price">$5,500</span>
                                                /annum
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" onClick={signUp}>
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        
                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports up to 50 subscriptions / 10,000 resources</p>

                                        <p style={{ lineHeight: "64px" }}>BYO Storage Account</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <div className="custom-pricing-column">
                                    <div class="plan-name-and-cost">
                                        <h4>Platinum</h4>

                                        <hr />

                                        <div class="subtitle">
                                            <p>For MSP's, Large Government Agencies, or Large MNC's</p>
                                        </div>

                                        <div class="price-on-request">Pricing on Request</div>
                                    </div>

                                    <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                            <div id="pricing-button">
                                                <button className="ui button button__component" href="mailto:info@tagmanager.app">
                                                    Get in Touch
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        
                                        <p>
                                            <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                            <Link to="feature/tag-health">Tag Health</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                        </p>

                                        <p>
                                            <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                        </p>

                                        <p>Supports <b>over</b> 50 subscriptions / 10,000 resources</p>

                                        <p>BYO Storage Account + <b>Hybrid SaaS Deployment Model</b></p>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    const panesMobile = [
        {
            menuItem: "Monthly",
            render: () => (
                <Tab.Pane attached={false}>
                    <div class="custom-pricing-grid-avd-mobile">
                        <Grid className="pricing-grid custom-grid single-mobile">
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Starter</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>For Small Teams and Cloud Environments </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$90</span>
                                                    /month
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />

                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>

                                            <p>Supports up to 1 subscription / 1,000 resources</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Premium</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>For Medium Sized Teams and Environments </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$250</span>
                                                    /month
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />

                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>

                                            <p>Supports up to 5 subscriptions / 5,000 resources</p>

                                            <p>BYO Storage Account</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Enterprise</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>Large Orgs. wanting to manage their cloud environment at scale </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$500</span>
                                                    /month
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />

                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>


                                            <p>Supports up to 50 subscriptions / 10,000 resources</p>

                                            <p>BYO Storage Account</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Platinum</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>For MSP's, Large Government Agencies, or Large MNC's</p>
                                            </div>

                                            <div class="price-on-request">Pricing on Request</div>
                                        </div>

                                        <div className="list-section">
                                        <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" href="mailto:info@tagmanager.app">
                                                        Get in Touch
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />
                                            
                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>
                                            
                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>


                                            <p>Supports <b>over</b> 50 subscriptions / 10,000 resources</p>

                                            <p>BYO Storage Account + <b>Hybrid SaaS Deployment Model</b></p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>AVD</h4>

                                            <hr />    

                                            <div class="subtitle">
                                                <p>Azure Virtual Desktop User Consumption Cost Reporting​</p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$0.35</span>
                                                    /user/month
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUpAVD}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />

                                            <p>AVD Dashboard</p>

                                            <p>Azure Virtual Desktop (AVD) User Consumption Report</p>

                                            <p>CSV Export</p>
                                        </div>

                                        <div className="bottom-div">
                                            <div class="custom-button-component">
                                                <a className="ui button button__component white msp" onClick={signUpAVD}>
                                                    Add to existing plan
                                                </a>
                                            </div>
                                            <p className="informativeSub">Can be purchased as an add-on or standalone plan </p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Annually",
            render: () => (
                <Tab.Pane attached={false}>
                    <div class="custom-pricing-grid-mobile">
                        <Grid className="pricing-grid custom-grid single-mobile">
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Starter</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>For Small Teams and Cloud Environments </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$990</span>
                                                    /annum
                                                </div>
                                            </div>
                                        </div>
                                
                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />

                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>


                                            <p>Supports up to 1 subscription / 1,000 resources</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Premium</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p class="sub">For Medium Sized Teams and Environments </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$2,750</span>
                                                    /annum
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />
                                            
                                            <p class="list">
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>

                                            <p>Supports up to 5 subscriptions / 5,000 resources</p>

                                            <p style={{ lineHeight: "64px" }}>BYO Storage Account</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Enterprise</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>Large Orgs. wanting to manage their cloud environment at scale </p>
                                            </div>
                                            <div className="price-div">
                                                <div class="price">
                                                    USD
                                                    <span className="large-price">$5,500</span>
                                                    /annum
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" onClick={signUp}>
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />
                                            
                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>

                                            <p>Supports up to 50 subscriptions / 10,000 resources</p>

                                            <p style={{ lineHeight: "64px" }}>BYO Storage Account</p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="custom-pricing-column">
                                        <div class="plan-name-and-cost">
                                            <h4>Platinum</h4>

                                            <hr />

                                            <div class="subtitle">
                                                <p>For MSP's, Large Government Agencies, or Large MNC's</p>
                                            </div>

                                            <div class="price-on-request">Pricing on Request</div>
                                        </div>

                                        <div className="list-section">
                                            <div class="pricing-button-div-alt">
                                                <div id="pricing-button">
                                                    <button className="ui button button__component" href="mailto:info@tagmanager.app">
                                                        Get in Touch
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <hr />
                                            
                                            <p>
                                                <Link to="feature/tags/">Tag Management</Link> and {"\n"}
                                                <Link to="feature/tag-health">Tag Health</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/arm-visualiser/">ARM Visualiser</Link>
                                            </p>

                                            <p>
                                                <Link to="feature/dashboard/">Personalised Dashboards</Link>
                                            </p>

                                            <p>Supports <b>over</b> 50 subscriptions / 10,000 resources</p>

                                            <p>BYO Storage Account + <b>Hybrid SaaS Deployment Model</b></p>
                                                                                    </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    return (
        
            <div>


                {isMobile == false && (
                    <div>
                        <div class="trial-buttons">
                            <div class="trial-buttons">
                                <button className="ui button button__component white msp" onClick={navigate} style={{ marginRight: "1px" }}>
                                    Use the free version
                                </button>

                                <button className="ui button button__component" onClick={signUp} style={{ marginLeft: "1px" }}>
                                    Trial the full version
                                </button>
                            </div>
                        </div>
                        
                        <Tab
                        menu={{
                            color: "white",
                            inverted: true,
                            attached: false,
                            tabular: false,
                        }}
                        panes={panes}
                        className="tabs-pricing"
                        />
                    </div>
                )}

                {isMobile == true && (
                    <div>
                        <div class="trial-buttons-mobile">
                            <div class="trial-buttons-mobile">
                                <button className="ui button button__component white msp" onClick={navigate} style={{ marginRight: "1px" }}>
                                    Use the free version
                                </button>

                                <button className="ui button button__component" onClick={signUp} style={{ marginLeft: "1px" }}>
                                    Trial the full version
                                </button>
                            </div>
                        </div>
                        
                        <Tab
                        menu={{
                            color: "white",
                            inverted: true,
                            attached: false,
                            tabular: false,
                        }}
                        panes={panesMobile}
                        className="tabs-pricing-mobile"
                        />
                    </div>
                )}
            
        </div>
    );
};

export default PricingComponent;
