import {Icon} from "semantic-ui-react";
import React from "react";
import "./ToastifyErrorMessage.css";

const ToastifyErrorMessage = (props) => {
	return (
		<div>
			<Icon onClick name="info" size="large" className="info-icon" />
			{props.message}
			<button class="ui red inverted button mini" onClick={() => props.toggleDiffModal(props.res)}>
				Update Breakdown
			</button>
		</div>
	);
};

export default ToastifyErrorMessage;
