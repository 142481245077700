let dropdownStylesTagEditor = {
	singleValue: (base) => ({...base, color: "#ffffff", background: "#39393d"}),
	menuList: (base) => ({...base, color: "#ffffff", background: "#39393d"}),
	dropdownIndicator: (base) => ({...base, background: "#39393d"}),
	indicatorsContainer: (base) => ({...base, background: "#39393d"}),
	dropdownIndicator: (base) => ({...base, padding: "5px"}),
	container: (base) => ({...base, color: "#ffffff", background: "#39393d", border: "#757575 1px solid"}),
	menu: (base) => ({...base, color: "#ffffff", background: "#39393d", border: "none"}),
	control: (base) => ({...base, color: "#ffffff", background: "#39393d", border: "none", height: "31px", minHeight: "30px"}),
	valueContainer: (base) => ({
		...base,
		background: "#39393d",
		color: "##ffffff",
		width: "100%"
	}),
	placeholder: (base) => ({...base, color: "#ffffff", fontSize: "14px"}),
	option: (base) => ({...base, color: "#ffffff"}),
	indicatorSeparator: (base) => ({...base, background: "#39393d"}),
	input: (base) => ({...base, color: "#ffffff", fontSize: "14px"})
};

export default dropdownStylesTagEditor;
