import React, {useState, Fragment, useEffect} from "react";
import {Table, Form, ItemMeta} from "semantic-ui-react";
import "./InfoBox.css";
const InfoBox = (props) => {
	const [scrollY, setScrollY] = useState(0);
	const [x, setX] = useState(document.body.clientWidth);

	const logit = () => {
		setScrollY(window.pageYOffset);
		setX(document.body.clientWidth);
	};

	useEffect(() => {
		const watchScroll = () => {
			window.addEventListener("scroll", logit);
			window.addEventListener("resize", logit);
		};

		watchScroll();

		return () => {
			window.removeEventListener("scroll", logit);
			window.removeEventListener("resize", logit);
		};
	});
	let cellId = "popup-" + props.number;
	let defaultValue = "";

	return (
		<Fragment>
			{/* eslint-disable-next-line react/prop-types */}

			<div className="tooltip-cell">
				<PopUp text={props.text} caHasLoaded={props.caHasLoaded} number={props.number} cellId={cellId} scrollY={scrollY} x={x} time={props.time} />
			</div>
		</Fragment>
	);
};
export default InfoBox;

const PopUp = ({text, caHasLoaded, number, cellId, scrollY, x, time}) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			if (document.querySelector("#" + cellId) !== null) {
				try {
					document.querySelector("#popup-circle" + number).classList.remove("hidden");
				} catch {}
			}
		}, time);
		return () => clearTimeout(timer);
	}, []);
	let top, left;
	if (document.querySelector("#" + cellId) != null) {
		if (document.querySelector("#" + cellId).getBoundingClientRect().top !== 0) {
			left = Math.round(document.querySelector("#" + cellId).getBoundingClientRect().left);
			top = Math.round(document.querySelector("#" + cellId).getBoundingClientRect().top);
		} else {
			top = 3000;
			left = 0;
		}
	} else {
		top = 3000;
		left = 0;
	}
	return (
		<Fragment>
			{document.querySelector("#" + cellId) !== null && (
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}
					id={"cell_tooltip_" + number}
					className="popup-class "
					style={
						caHasLoaded && number == 1
							? {
									transform: `translate(${left}px, ${top}px)`,
									position: "fixed",
									zIndex: 2,
									top: "70px",
									left: "88px"
							  }
							: caHasLoaded && number == 2
							? {
									transform: `translate(${left}px, ${top}px)`,
									position: "fixed",
									zIndex: 2,
									top: "128px",
									left: "-161px"
							  }
							: {opacity: 0}
					}>
					<div className="popup-circle">
						<div id={"popup-circle" + number} className={"popup-text-" + number + " hidden"}>
							<p>{text}</p>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};
