import React from "react";
import { Grid, Container, Button, Icon, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./FeatureCatalog.css";
const FeatureCatalog = (props) => {
    return (
        <section id="catalog" className={props.color}>
            <Container>
                <div id="product-catalog">
                    <h3>A full suite of powerful components</h3>
                    <Grid centered>
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/tags">
                                <div className="catalog-item">
                                    <div className="img-container">
                                        <img alt="Cloudclarity Tag Manager Feature" src="/images/feature-catalog-1-v2.png" />
                                    </div>

                                    <div className="text-container">
                                        <h4 style={{ textAlign: "center" }}>Tag Manager</h4>
                                        <p>Helps you manage your tags at scale</p>
                                    </div>
                                </div>
                            </Link>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/cost-summary">
                                <div className="catalog-item">
                                    <div className="img-container">
                                        <img alt="Cloudclarity Cost Summary Feature" src="/images/feature-catalog-2-v2.png" />
                                    </div>
                                    
                                    <div className="text-container">
                                        <h4 style={{ textAlign: "center" }}>Cost Summary</h4>
                                        <p>Get quantifiable insights and allocate cost​</p>
                                    </div>
                                </div>
                            </Link>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/dashboard">
                                <div className="catalog-item">
                                    <div className="img-container">
                                        <img alt="Cloudclarity Personalised Dashboard Feature" src="/images/feature-catalog-3-v2.png" />
                                    </div>
                                    
                                    <div className="text-container">
                                        <h4 style={{ textAlign: "center" }}>Personalised Dashboards</h4>
                                        <p>See the picture of your environement relevant to you</p>
                                    </div>
                                </div>
                            </Link>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/tag-health">
                                <div className="catalog-item">
                                    <div className="img-container">
                                        <img alt="Cloudclarity Tag Health Feature" src="/images/feature-catalog-5-v2.png" />
                                    </div>
                                    
                                    <div className="text-container">
                                        <h4 style={{ textAlign: "center" }}>Tag Health</h4>
                                        <p>Verify your environments best practice with Tag Health</p>
                                    </div>
                                </div>
                            </Link>
                        </Grid.Column>
                        {/* obselete, may remove completely in the future
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/avd">
                                <div className="catalog-item">
                                    <div className="img-container">
                                        <img alt="Cloudclarity AVD Feature" src="/images/feature-catalog-6.png" />
                                    </div>
                                    
                                    <div className="text-container">
                                        <h4 style={{ textAlign: "center" }}>AVD</h4>
                                        <p>Report on metrics important to your business with CloudClarity for AVD</p>
                                    </div>
                                </div>
                            </Link>
                        </Grid.Column> */}
                        {/* <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/arm-visualiser">
                                <div className="catalog-item">
                                    <img src="/images/feature-catalog-4.png" alt="arm visualiser page" />
                                    <h4 style={{ textAlign: "center" }}>ARM Visualiser</h4>
                                    <p>Visualise resources and dependencies of a Resource Group</p>
                                </div>
                            </Link>
                        </Grid.Column> */}
                        {/* <Grid.Column mobile={16} tablet={8} computer={5}>
                            <Link to="/feature/cost-explorer">
                                <div className="catalog-item">
                                    <img src="/images/cost-explorer-main.png" alt="cost explorer page" />
                                    <h4 style={{ textAlign: "center" }}>Cost Explorer</h4>
                                    <p>Compare costs between date ranges</p>
                                </div>
                            </Link>
                        </Grid.Column> */}
                    </Grid>
                    <div style={{ height: "32px" }}></div>
                </div>
            </Container>
        </section>
    );
};

export default FeatureCatalog;
