import React, { useState, useContext, useRef, useEffect, Fragment, lazy } from "react";
import "./LandingPageContent.css";
import "../../../pages/about/about.css";
import "../../../pages/pricing/pricing.css";
import "../../tag-manager/Tables/SubscriptionTableWrapper/ResourcesTableWrapper.css";

import checkIfImageExists from "../../../utils/ResourceImage";
import { Grid, Container, Button, Icon, List } from "semantic-ui-react";
import EditTagModal from "./DummyComponents/EditTagModal";
import AppContext from "../../../utils/AppContext";
import { Link, useHistory } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group"; // ES6
import InfoBox from "./DummyComponents/InfoBox";
// import Swiper core and required components
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import PricingComponent from "./PricingComponent";
import FeatureCatalog from "../../elements/FeatureCatalog";

import { useMediaQuery } from 'react-responsive';

SwiperCore.use([Autoplay]);

const LandingPageContent = () => {
    const [activeTool, setActiveTool] = useState("cost-allocator");
    const [caHasLoaded, setCAHasLoaded] = useState(false);
    const [DummyMenuActive, setDummyMenuActive] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    let context = useContext(AppContext);
    let history = useHistory();

    const signUp = () => {
        window.location.href = "https://portal.azure.com/#create/cubesys.cloud_clarity";
    };
    const tryForFree = () => {
        window.location.href = "https://portal.azure.com/#create/cubesys.cloud_clarity";
    };

    return (
        <div id="landing-page-content">
            <div id="hero-call-to-action">
                <Container style={{ height: "100%" }}>
                    <Grid doubling columns={2} className="pricing-grid">
                        <Grid.Column width={8}>
                            <div className="hero-content">
                                <h1>Take control of accountability and compliance in Azure.</h1>
                                <p>Our Governance platform creates quantifiable insights in real-time, not when your Azure Bill rolls in.</p>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {" "}
                                    <button style={{ marginRight: "8px" }} className="ui button button__component big" onClick={tryForFree}>
                                        Start Trial Now
                                    </button>
                                    
                                    <button
                                        className="ui button button__component big"
                                        
                                        onClick={() => {
                                            window.open("https://outlook.office365.com/owa/calendar/CloudClarity@cubesys.com.au/bookings/");
                                        }}
                                    >
                                        Book a Demo
                                    </button>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column className="animation-column">
                            <div id="hero-animation">
                                <div id="IT">
                                    <div id="D3Cube2">
                                        <div id="side12" className="D3Cube4-div"></div>
                                        <div id="side22" className="D3Cube4-div"></div>
                                        <div id="side32" className="D3Cube4-div">
                                            <div className="document">
                                                <p>IT Dept.</p>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-4">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" alt="tag1" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        it-server <span> $54,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-5">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" alt="tag2" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        user-tracking <span> $6900</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-8">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" alt="tag3" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        VM1<span> $7,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="finance">
                                    <div id="D3Cube3">
                                        <div id="side13"></div>
                                        <div id="side23"></div>
                                        <div id="side33">
                                            <div className="document">
                                                <p>Finance Dept.</p>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-6">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" alt="tag1" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        fiance-db <span> $87,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-7">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" id="final4" alt="tag2" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        firewall-7 <span> $42,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-9">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" id="final6" alt="tag3" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        Storage <span> $34,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="untracked">
                                    <div id="D3Cube4">
                                        <div className="D3Cube4-div" id="side14"></div>
                                        <div className="D3Cube4-div" id="side24"></div>
                                        <div className="D3Cube4-div" id="side34">
                                            <div className="document">
                                                <p> ??? Untracked</p>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-1">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" id="final1" alt="tag1" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        firewall-7 <span> $42,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tile-cube-wrapper-2">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" id="final2" alt="tag1" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        VM1<span> $7,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="tile-cube-wrapper-3">
                                            <div className="tile-cube-top"></div>
                                            <div className="tile-cube-right"></div>
                                            <div className="tile-cube-front">
                                                <div className="res-group">
                                                    <img src="/images/tag1.svg" id="final3" alt="tag3" />
                                                    <i
                                                        className="large icon middle aligned res"
                                                        style={{
                                                            backgroundImage: `url(` + checkIfImageExists("/grg/r/gr") + `)`,
                                                        }}
                                                    />
                                                    <p>
                                                        Storage <span> $34,000</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="wrapD3Cube">
                                    <div id="D3Cube">
                                        <div id="side1">
                                            <div className="bg"></div>
                                        </div>
                                        <div id="side3"></div>
                                        <div id="side4"></div>
                                        <div id="side6"></div>
                                    </div>
                                </div>

                                <img src="/images/tag2.svg" id="it1" alt="Azure tagging" />
                                <img src="/images/tag1.svg" id="finance1" alt="Bulk tagging" />
                                <img src="/images/tag2.svg" id="finance2" alt="Azure Bill" />
                                <img src="/images/tag1.svg" id="blue1" alt="Azure Cost Summary" />
                            </div>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>

            {activeTool == "cost-allocator" && (
                <Fragment>
                    {DummyMenuActive == false && <InfoBox number={1} idending={1} caHasLoaded={caHasLoaded} text={"Select here to allocate costs to a different tag value."} time={3000} />}
                    <InfoBox number={2} idending={2} caHasLoaded={caHasLoaded} text={"Select a resource to allocate it to another department."} time={6000} />
                </Fragment>
            )}

            <div className={"landing-page-content__component " + activeTool}>
                <section>
                    <Container>
                        <Grid>
                            <Grid.Column>
                                <div className="header-container">
                                    <h2 id="tool-title">Drive Azure cloud governance with our simple and powerful&nbsp;set&nbsp;of&nbsp;tools. </h2>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <div style={{ display: "flex", alignContent: "center" }} id="tool-switcher">
                        <div className="button-grid">
                            <Button
                                basic
                                color="blue"
                                active={activeTool == "cost-allocator"}
                                onClick={() => {
                                    setActiveTool("cost-allocator");
                                }}
                            >
                                <h2>Azure Cost Allocation in Realtime</h2>
                            </Button>
                            <Button
                                basic
                                color="blue"
                                active={activeTool == "db"}
                                onClick={() => {
                                    setActiveTool("db");
                                }}
                            >
                                <h2>Personalised Environment Summary</h2>
                            </Button>
                            <Button
                                basic
                                color="blue"
                                active={activeTool == "ca"}
                                onClick={() => {
                                    setActiveTool("ca");
                                }}
                            >
                                <h2>Azure Cost Summary</h2>
                            </Button>
                            <Button
                                basic
                                color="blue"
                                active={activeTool == "vs"}
                                onClick={() => {
                                    setActiveTool("vs");
                                }}
                            >
                                <h2>Native Azure tagging in bulk</h2>
                            </Button>
                        </div>
                    </div>
                    <div className="tool-message">
                        {activeTool == "cost-allocator" && (
                            <p>
                                See how easy it is to compare the cost of tag values in real-time with our Azure Cost Allocator. Try allocating a cost with our <b>interactive demo below!</b>
                            </p>
                        )}
                        {activeTool == "db" && <p>A highly dynamic and personalized view of your resources, spending and tagging health across all your cloud scopes.</p>}
                        {activeTool == "ca" && (
                            <p>Visualise your historic and currently paritioned tag spending. See comparisons between departments, applications and any other way you tag your environment.</p>
                        )}
                        {activeTool == "vs" && (
                            <p>
                                Enables bulk tagging of resources from any scope. You can tag by import/export csv or the portal view, all while writing everything directly back to your Azure
                                Instance.
                            </p>
                        )}
                    </div>
                    <div
                        style={{
                            maxWidth: "80vw",
                            width: "100%",
                            margin: "0 auto",
                        }}
                    >
                        
                        <div className={activeTool != "cost-allocator" ? "video-section-home not-ca" : "video-section-home"}>
                            <CSSTransitionGroup transitionName="example" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                                {activeTool == "cost-allocator" && (
                                    <Fragment>
                                        <EditTagModal editTagModalKey={"department"} setCAHasLoaded={setCAHasLoaded} setDummyMenuActive={setDummyMenuActive} />
                                        <div className="edit-tag-modal ca" style={{ padding: "0" }}>
                                            <AutoPlaySilentVideo video="/videos/cost-alloc.m4v" />
                                        </div>
                                    </Fragment>
                                )}
                                {activeTool == "db" && (
                                    <div className="edit-tag-modal always video" style={{ padding: "0" }}>
                                        <AutoPlaySilentVideo video="/videos/dashboard.m4v" />
                                    </div>
                                )}
                                {activeTool == "ca" && (
                                    <div className="edit-tag-modal always video" style={{ padding: "0" }}>
                                        <AutoPlaySilentVideo video="/videos/cost-allocation.m4v" />
                                    </div>
                                )}
                                {activeTool == "vs" && (
                                    <div className="edit-tag-modal always video" style={{ padding: "0" }}>
                                        <AutoPlaySilentVideo video="/videos/vis.m4v" />{" "}
                                    </div>
                                )}
                            </CSSTransitionGroup>
                        </div>
                    </div>
                </section>
            </div>

            <section id="why-tag-manager">
                <Container>
                    <section>
                        <div className="content">
                            <Grid doubling columns={2} className="pricing-grid">
                                <Grid.Column>
                                    <div className="new-content">
                                        <h3>Reduce the complexity of your Azure governance.</h3>
                                        <p>
                                            CloudClarity is the simplest way to create a centralised view of your tenants' resources and tags. The tool allows you to create personalised views for both
                                            bulk broad-spectrum and precision attribution updates. See more{" "}
                                            <a
                                                href="#tool-switcher"
                                                onClick={() => {
                                                    setActiveTool("vs");
                                                }}
                                            >
                                                here
                                            </a>
                                            .
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="new-content cogs" style={{ maxWidth: "100%" }}>
                                        <div className="cog-box">
                                            <AutoPlaySilentVideo video="/images/consistency.webm" />
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </section>
                </Container>
            </section>
            <section id="why-tag-manager2">
                <Container>
                    <section>
                        <div className="content">
                            <Grid doubling columns={2} className="pricing-grid">
                                <Grid.Column>
                                    <div className="new-content cogs" style={{ maxWidth: "100%" }}>
                                        <div className="cog-box">
                                            <AutoPlaySilentVideo video="/images/consistency2.webm" />
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="new-content">
                                        <h3>Attribute costs and make critical decisions. Fast.</h3>
                                        <p>
                                            Our tool makes cost attribution easy, facilitating faster identification of cost-saving initiatives. By providing clear visualisations of a billing
                                            environment, anyone with an Azure cloud instance can easily track down unnecessary costs. Explore how you can do this quickly with our too{" "}
                                            <a
                                                href="#tool-switcher"
                                                onClick={() => {
                                                    setActiveTool("ca");
                                                }}
                                            >
                                                here
                                            </a>
                                            .
                                        </p>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </section>
                </Container>
            </section>
            <section id="why-tag-manager3">
                <Container>
                    <section>
                        <div className="content">
                            <Grid doubling columns={2} className="pricing-grid">
                                <Grid.Column>
                                    <div className="new-content">
                                        <h3>Validate against the Microsoft Cloud Adoption Framework or any other set&nbsp;of&nbsp;tags.</h3>
                                        <p>
                                            Our powerful Tag Health Validator lets you to validate your tag usage and overall tag health across any scope in your Azure tenant, while also allowing you
                                            to fix tags and generate reports with a few clicks.{" "}
                                            <button class="ui button button__component big" style={{ margin: "8px 8px 8px 0" }}>
                                                Start Trial Now
                                            </button>
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="new-content cogs" style={{ maxWidth: "100%" }}>
                                        <div className="cog-box">
                                            <img
                                                src="/images/homepage-screenshot-3.png"
                                                alt="Tag Health Image"
                                                style={{
                                                    opacity: ".9",
                                                    maxWidth: "100%",
                                                    imagerRendering: "pixelated",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </section>
                </Container>
            </section>
            <section></section>
            <FeatureCatalog color={"black"} />

            <section id="why">
                <Container>
                    <h3 style={{ textAlign: "center" }}>Why Our Customers Use CloudClarity</h3>
                    
                    {isMobile == false && (
                         <Grid>
                            <Grid.Row>
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Enable accurate charge-backs</p>
                                </div>
    
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Enable DevOps and automation</p>
                                </div>
    
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Identify cost drivers and reduce costs</p>
                                </div>
                            </Grid.Row>
    
                            <Grid.Row>
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Access control and compliance</p>
                                </div>
    
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Accountability of system ownership</p>
                                </div>
    
                                <div style={{ display: "flex", padding: 10, width: '33%' }}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Achieve practical Cloud Governance</p>
                                </div>
                            </Grid.Row>
                         </Grid>
                    )}

                    {isMobile == true && (
                        <Grid>
                            <Grid.Column>
                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Enable accurate charge-backs</p>
                                </div>

                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Enable DevOps and automation</p>
                                </div>

                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Identify cost drivers and reduce costs</p>
                                </div>
                            
                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Access control and compliance</p>
                                </div>

                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Accountability of system ownership</p>
                                </div>

                                <div style={{ display: "flex", padding: 10}}>
                                    <Icon
                                        name="check"
                                        style={{
                                            color: "#3376cd",
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            padding: 2,
                                        }}
                                    ></Icon>
                                    <p>Achieve practical Cloud Governance</p>
                                </div>
                            </Grid.Column>
                        </Grid>
                    )}
                        
                        {/* <Grid.Column>
                            <div className="content">
                                <h3 style={{ textAlign: "center" }}>Why Our Customers use CloudClarity</h3>
                                <Swiper
                                    spaceBetween={12} //changed from 16 by d.c.
                                    slidesPerView={3} //changed from 5 by d.c.
                                    autoplay={{ delay: 2000 }}
                                    loop={true}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        0: {
                                            width: 0,
                                            slidesPerView: 3, 
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 3, //changed from 4 by d.c.
                                        },
                                        992: {
                                            width: 992,
                                            slidesPerView: 3, //changed from 5 by d.c.
                                        },
                                    }}
                                >
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Enable accurate charge-backs</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Enable DevOps and automation</p>
                                        </div>
                                    </SwiperSlide>{" "}
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Identify cost drivers and reduce costs</p>
                                        </div>
                                    </SwiperSlide>{" "}
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Access control and compliance</p>
                                        </div>
                                    </SwiperSlide>{" "}
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Accountability of system ownership</p>
                                        </div>
                                    </SwiperSlide>{" "}
                                    <SwiperSlide>
                                        <div style={{ display: "flex" }}>
                                            <Icon
                                                name="check"
                                                style={{
                                                    color: "#3376cd",
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                }}
                                            ></Icon>
                                            <p>Achieve practical Cloud Governance</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </Grid.Column> 
                    </Grid> */}
                </Container>
            </section>
            <section id="pricing">
                <Container>
                    <div>
                        <h3>Pricing plans to suit your needs</h3>

                        <PricingComponent />
                        <p>
                            You can find a Business case and more about how we bill you <Link to="/purchasing">here</Link>
                        </p>
                    </div>

                    <div className="cta">
                        <hr />
                        <h3>Ready to take control of Azure?</h3>
                        <p style={{ fontSize: "18px" }}>Just login with your Microsoft Azure Account Login to Active your free trial account today!* </p>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {" "}
                            <button
                                className="ui button button__component big"
                    
                                onClick={tryForFree}
                            >
                                Start Trial
                            </button>
                            <button
                                className="ui button button__component big"
                                
                                onClick={() => {
                                    window.open("https://outlook.office365.com/owa/calendar/CloudClarity@cubesys.com.au/bookings/");
                                }}
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default LandingPageContent;

const AutoPlaySilentVideo = (props) => {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    });
    return (
        <video className={props.className} ref={videoRef} loop autoPlay muted playsInline>
            <source src={props.video} type="video/mp4" />
        </video>
    );
};
