let dropDownStylesTagEditorValues = {
    singleValue: (base) => ({ ...base, color: "#ffffff", background: "#39393d" }),
    menuList: (base) => ({ ...base, color: "#ffffff", background: "#39393d" }),
    dropdownIndicator: (base) => ({ ...base, background: "#39393d" }),
    indicatorsContainer: (base) => ({ ...base, background: "#39393d" }),
    container: (base) => ({ ...base, color: "#ffffff", background: "#39393d", border: "#757575" }),
    menu: (base) => ({ ...base, color: "#ffffff", background: "#39393d", border: "#757575" }),
    control: (base) => ({ ...base, color: "#ffffff", background: "#39393d", border: "#757575 1px solid" }),
    valueContainer: (base) => ({
        ...base,
        background: "#39393d",
        color: "#ffffff",
        width: "100%",
    }),
    option: (base) => ({ ...base, color: "#ffffff" }),
    indicatorSeparator: (base) => ({ ...base, background: "#39393d" }),
    input: (base) => ({ ...base, color: "#ffffff" }),
};

export default dropDownStylesTagEditorValues;
