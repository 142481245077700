import React, {Component} from "react";

import CommonPageLayoutFluid from "components/page-layout/CommonPageLayoutFluid";
import LandingPageContent from "components/landing/LandingPageContent";

class MainLandingPage extends Component {
	render() {
		return (
			<>
				<CommonPageLayoutFluid className="main-landing-page__component"></CommonPageLayoutFluid>
				<LandingPageContent />
			</>
		);
	}
}

export default MainLandingPage;
