import React, {useEffect, useState, useContext} from "react";
import {Icon, Accordion, Modal, Button, Dimmer, Loader, Progress} from "semantic-ui-react";
import checkIfImageExists from "../../../../../utils/ResourceImage";
import ResourceURL from "../../../../../utils/ResourceURL";
import tagIcon from "../../../../tag-manager/Tables/CostManagementTableWrapper/icons/ic_tag.svg";

import CreatableSelect from "react-select/creatable";
import dropDownStyles from "../../../../../utils/dropdownStyles";
import "./EditTagModal.css";
import AppContext from "../../../../../utils/AppContext";
import dropdownStylesTagEditor from "../../../../../utils/dropdownStylesTagEditor";
import dropdownStylesDashDisabled from "../../../../../utils/dropdownStylesDashDisabled";
import dropDownStylesTagEditorValuesDummy from "../../../../../utils/dropDownStylesTagEditorValues";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastifyMessage from "../../../../tag-manager/Components/ToastifyMessage";
import ToastifyErrorMessage from "../../../../tag-manager/Components/ToastifyErrorMessage";
import Select, {components} from "react-select";
import SubEfficientInput from "../../../../tag-manager/Components/SubEfficientInput";
import _, {filter, set} from "lodash";
import InfoBox from "../InfoBox";

const createOption = (label) => ({
	label,
	value: label
});

let groupByOptions = [
	{value: "cost", label: "Cost"},
	{value: "rg", label: "RG Name"},
	{value: "res", label: "Res Name"},
	{value: "type", label: "Type"}
];
const EditTagModal = (props) => {
	const [content, setContent] = useState("loading");
	const [liveContent, setLiveContent] = useState("");
	const [progress, setProgress] = useState(0);

	const [activeIndexes, setActiveIndexes] = useState([2]);

	const [newAssign, setnewAssign] = useState({label: "Finance", value: "Finance"});
	const [currentlySelected, setCurrentlySelected] = useState([]);

	const [options, setOptions] = useState("");

	const [deleteMode, setDeleteMode] = useState(false);
	const [deleteList, setDeleteList] = useState([]);

	const [numberOfRequestsCompleted, setNumberOfRequestsCompleted] = useState(0);
	const [numberOfRequests, setNumberOfRequests] = useState(0);
	const [groupBy, setGroupBy] = useState({value: "cost", label: "Cost"});
	const [filterInput, setFilterInput] = useState("");
	const context = useContext(AppContext);

	const getCostEditView = (tagKey, subscriptionId, fromRetry) => {
		setContent("loading");
		var requestOptions = {
			method: "GET",
			redirect: "follow"
		};

		fetch("https://dummyaboutpagedata.blob.core.windows.net/dummydata/dummy-costeditview.json", requestOptions)
			.then((res) => res.json())
			.then((res) => {
				let cleanres = JSON.parse(JSON.stringify(res));
				let tagOptions = [];

				cleanres.values.forEach((value) => {
					let totalCost = 0;

					value.resources.forEach((res) => {
						let resTotalCost = 0;
						Object.entries(res.cost).forEach(([key, Value]) => {
							totalCost += parseFloat(Value.substring(1));
							value["totalCost"] = totalCost;

							resTotalCost += parseFloat(Value.substring(1));
							res["totalCost"] = resTotalCost;
						});
					});
					cleanres.values.forEach((value) => {
						value.resources.sort(compare);
					});
				});
				cleanres.values.forEach((value) => {
					let obj = {value: value["tag-value"], label: value["tag-value"]};
					tagOptions.push(obj);
				});

				let bool1 = true;
				let bool2 = true;
				cleanres.values.forEach((value) => {
					if (value["tag-value"] == "AFP_UNTAGGED") {
						bool1 = false;
					}
					if (value["tag-value"] == "AFP_EMPTY") {
						bool2 = false;
					}
				});

				if (bool1) {
					cleanres["values"].push({"tag-value": "AFP_UNTAGGED", resources: []});
				}
				if (bool2) {
					cleanres["values"].push({"tag-value": "AFP_EMPTY", resources: []});
				}

				setOptions(tagOptions);
				setContent(cleanres);
				setLiveContent(cleanres);
				props.setCAHasLoaded(true);
			})
			.catch((err) => {
				console.log(err);
				if (fromRetry) {
					setContent("error");
				}
			});
	};

	const updateFast = (query) => {
		const thirtyFPSInput = _.debounce(() => {
			setFilterInput(query);
		});
		thirtyFPSInput();
	};

	const sendUpdateTags = async (tagKey, fromRetry) => {
		let resources = [];
		let versionHistoryEntry = false;
		liveContent.values.forEach((value) => {
			value.resources.forEach((res) => {
				let hasChanged = false;
				let resourceIDList = [];
				content.values.forEach((contentValue) => {
					if (contentValue["tag-value"] == value["tag-value"]) {
						contentValue.resources.forEach((contentRes) => {
							resourceIDList.push(contentRes.id);
						});
					}
				});

				if (resourceIDList.includes(res.id) == false) {
					let tagValue = value["tag-value"];
					if (tagValue == "AFP_EMPTY") {
						tagValue = "";
					}
					let newRes = {};
					let potentialNewKey = JSON.parse('{"' + props.editTagModalKey + '":"' + res.tags[props.editTagModalKey] + '"}');
					if (res.tags[props.editTagModalKey]) {
					} else {
						potentialNewKey[props.editTagModalKey] = "";
					}
					newRes["resourceId"] = res.id;
					newRes["name"] = res.name;
					newRes["tags"] = JSON.parse(JSON.stringify(res.tags));
					newRes["tags"][props.editTagModalKey] = tagValue;
					newRes["tagsUpdated"] = potentialNewKey;
					resources.push(newRes);
				}
			});
		});

		setContent("updating");

		let numberOfRequests = Math.ceil(resources.length / 12);
		let numberOfRequests1 = Math.ceil(resources.length / 12);

		setNumberOfRequests(resources.length);
		let splitReqs = [];
		let i = 1;
		let j = 0;
		let k = 0;
		let x = 0;

		while (i <= numberOfRequests) {
			k = 0;
			splitReqs[x] = [];
			while (j < 12 * i && j < resources.length) {
				splitReqs[x][k] = resources[j];
				k++;
				j++;
			}
			x++;
			i++;
		}
		i = 0;
		let sumTotalResponse = [];
		let errorCount = 0;
		let globalStatus = 200;
		while (i < numberOfRequests) {
			if (i == numberOfRequests - 1) {
				versionHistoryEntry = true;
			}
			setNumberOfRequestsCompleted(i);
			await context
				.sendCostUpdateTags(splitReqs[i], versionHistoryEntry, resources, false)
				.then((res) => {
					if (i == 0) {
						sumTotalResponse = res[0];
					} else {
						res[0]["update-summary"].response[0].tags.forEach((tag) => {
							let tagExists = false;
							sumTotalResponse["update-summary"].response[0].tags.forEach((existingTag) => {
								if (existingTag.name == tag.name) {
									tagExists = true;
								}
							});
							if (!tagExists) {
								let newTag = JSON.parse(JSON.stringify(tag));
								tag.resources.forEach((response) => {
									sumTotalResponse["update-summary"].response[0].tags.push(newTag);
								});
							} else {
								sumTotalResponse["update-summary"].response[0].tags.forEach((existingTag) => {
									if (existingTag.name == tag.name) {
										tag.resources.forEach((newRes) => {
											existingTag.resources.push(newRes);
										});
									}
								});
							}
						});
					}
					// appending resources
					if (res[1] !== 200 && res[1] !== 204) {
						// Appending reosurces errors

						res[0]["update-summary"].response[0].tags.forEach((tag) => {
							tag.resources.forEach((response) => {
								if (response["azure-message"] !== "Success") {
									errorCount++;
								}
							});
						});
						globalStatus = res[1];
					}
				})
				.catch((err) => {
					// Theres an actual error with the request, either 404, or 500. investigation is needed

					setContent("error");
				});
			i++;
		}

		if (globalStatus !== 200) {
			// Mcdodge are ia - theres an error but it might not be a bad error - go to error section
			toast.dark(
				<ToastifyErrorMessage
					sub={props.subscriptionId}
					toggleDiffModal={props.toggleDiffModal}
					res={sumTotalResponse}
					message={errorCount > 1 ? "Couldnt update " + errorCount + " resources." : "Couldnt update " + errorCount + " resource."}
				/>,
				{
					toastId: Math.random(),
					position: "bottom-right",
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					progressClassName: "error-progress-bar"
				}
			);
		} else {
			// Success!
			console.log("success!");
			toast.dark(<ToastifyMessage sub={props.subscriptionId} toggleDiffModal={props.toggleDiffModal} res={sumTotalResponse} />, {
				toastId: Math.random(),
				position: "bottom-right",
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				progressClassName: "success-progress-bar"
			});
		}
		setContent("syncing");
		console.log("forcing update...");
		props.forceUpdate(props.subscriptionId);
	};

	const sendDeleteTags = (tagKey, fromRetry) => {
		setContent("updating");
		let Ids = {};
		deleteList.forEach((key) => {
			content.values.forEach((value) => {
				value.resources.forEach((res) => {
					if (res.id == key) {
						let tagValue = value["tag-value"];
						if (tagValue == "AFP_EMPTY") {
							tagValue = "";
						}
						Ids[key] = tagValue;
					}
				});
			});
		});
		context
			.sendCostDeleteTags(Ids, props.subscriptionId, props.editTagModalKey, false)
			.then((response) => {
				if (response[1] !== 200 && response[1] !== 204) {
					// Mcdodge are ia - theres an error but it might not be a bad error - go to error section
					let errorCount = 0;
					response[0]["update-summary"].response[0].tags.forEach((tag) => {
						tag.resources.forEach((response) => {
							if (response["azure-message"] !== "tagDeleted") {
								errorCount++;
							}
						});
					});
					toast.dark(
						<ToastifyErrorMessage
							sub={props.subscriptionId}
							toggleDiffModal={props.toggleDiffModal}
							res={response[0]}
							message={errorCount > 1 ? "Couldnt update " + errorCount + " resources." : "Couldnt update " + errorCount + " resource."}
						/>,
						{
							toastId: Math.random(),
							position: "bottom-right",
							hideProgressBar: false,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							progressClassName: "error-progress-bar"
						}
					);
				} else {
					// Success!

					toast.dark(<ToastifyMessage sub={props.subscriptionId} toggleDiffModal={props.toggleDiffModal} res={response[0]} />, {
						toastId: Math.random(),
						position: "bottom-right",
						hideProgressBar: false,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						progressClassName: "success-progress-bar"
					});
				}
				setContent("syncing");
				props.forceUpdate(props.subscriptionId);
			})
			.catch((err) => {
				setContent("error");
			});
	};

	const reset = () => {
		let cleanContent = JSON.parse(JSON.stringify(content));

		let tagOptions = [];
		if (cleanContent.values !== undefined) {
			cleanContent.values.forEach((value) => {
				let obj = {value: value["tag-value"], label: value["tag-value"]};
				tagOptions.push(obj);
			});

			let bool1 = true;
			let bool2 = true;
			cleanContent.values.forEach((value) => {
				if (value["tag-value"] == "AFP_UNTAGGED") {
					bool1 = false;
				}
				if (value["tag-value"] == "AFP_EMPTY") {
					bool2 = false;
				}
			});

			if (bool1) {
				cleanContent["values"].push({"tag-value": "AFP_UNTAGGED", resources: []});
			}
			if (bool2) {
				cleanContent["values"].push({"tag-value": "AFP_EMPTY", resources: []});
			}
		}
		setnewAssign({label: "Finance", value: "Finance"});
		setOptions(tagOptions);
		setLiveContent(cleanContent);
		setDeleteList([]);
	};

	const compare = (a, b) => {
		if (a.totalCost > b.totalCost) {
			return -1;
		}
		if (a.totalCost < b.totalCost) {
			return 1;
		}
		return 0;
	};

	const handleClick = (index) => {
		let cleanActives = JSON.parse(JSON.stringify(activeIndexes));
		if (cleanActives.includes(index)) {
			cleanActives = cleanActives.filter((item) => item !== index);
		} else {
			cleanActives.push(index);
		}
		setActiveIndexes(cleanActives);
	};

	const updateAllDescendants = (e, value) => {
		if (newAssign !== null && newAssign.value !== "" && newAssign.value !== null) {
			let resToAdd = [];
			let cleanContent = JSON.parse(JSON.stringify(liveContent));
			cleanContent.values.forEach((cleanVal) => {
				if (cleanVal["tag-value"] == value["tag-value"]) {
					resToAdd = JSON.parse(JSON.stringify(cleanVal.resources));
					cleanVal.resources = [];
				}
			});
			cleanContent.values.forEach((cleanVal) => {
				if (cleanVal["tag-value"] == newAssign.value) {
					resToAdd.forEach((res) => {
						cleanVal.resources.push(res);
					});
				}
			});
			setLiveContent(cleanContent);
		}
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const addToCurrentSelected = (e, res, value) => {
		if (newAssign !== null && newAssign.value !== "" && newAssign.value !== null) {
			let cleanContent = JSON.parse(JSON.stringify(liveContent));
			cleanContent.values.forEach((cleanVal) => {
				if (cleanVal["tag-value"] == value["tag-value"]) {
					cleanVal.resources.forEach((cleanRes, index) => {
						if (cleanRes.id == res.id) {
							cleanVal.resources.splice(index, 1);
						}
					});
				} else if (cleanVal["tag-value"] == newAssign.value) {
					cleanVal.resources.push(res);
				}
			});
			setLiveContent(cleanContent);
		}
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const handleChange = (newValue, actionMeta) => {
		setnewAssign(newValue);
	};
	const handleCreate = (inputValue) => {
		const newOption = createOption(inputValue);

		setOptions([...options, newOption]);
		setnewAssign(newOption);

		let cleanContent = JSON.parse(JSON.stringify(liveContent));
		cleanContent.values.push({
			"tag-value": inputValue,
			resources: [],
			totalCost: 0,
			new: true
		});
		setLiveContent(cleanContent);
	};

	const toggleDeleteMode = () => {
		reset();
		setDeleteList([]);
		setDeleteMode(!deleteMode);
	};

	const addAllToDeleteList = (e, value) => {
		e.preventDefault();
		let cleanDeleteList = JSON.parse(JSON.stringify(deleteList));
		value.resources.forEach((res) => {
			if (cleanDeleteList.includes(res.id) == false) {
				cleanDeleteList.push(res.id);
			}
		});
		setDeleteList(cleanDeleteList);

		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const addToDeleteList = (e, res) => {
		e.preventDefault();
		let cleanDeleteList = JSON.parse(JSON.stringify(deleteList));
		cleanDeleteList.push(res.id);
		setDeleteList(cleanDeleteList);

		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	useEffect(() => {
		if (props.editTagModalKey !== "") {
			getCostEditView(props.editTagModalKey, props.subscriptionId, false);
			setCurrentlySelected([]);
			setnewAssign({label: "Finance", value: "Finance"});
			setContent("loading");
			setActiveIndexes([2]);
			setOptions("");
			setLiveContent("");
			reset();
		}
	}, [props.open, props.loading]);

	let NewTotal = 0.0;
	if (liveContent.values !== undefined) {
		liveContent.values
			.filter((value) => {
				if (newAssign !== null) {
					return value["tag-value"] == newAssign.value;
				} else {
					return true;
				}
			})
			.forEach((value) => {
				value.resources.forEach((res) => {
					Object.entries(res.cost).forEach(([key, Value]) => {
						NewTotal += parseFloat(Value.substring(1));
					});
				});
			});
	}

	let editing = newAssign !== null && newAssign.value !== undefined && newAssign.value !== "";

	// Selected tags, changes what values are visible in filter values
	const onChangeGroup = (selectedOptions) => {
		setGroupBy(selectedOptions);
	};

	const sortSwitcher = (a, b) => {
		if (groupBy.value == "res") {
			return sortByName(a, b);
		} else if (groupBy.value == "rg") {
			return sortByRGName(a, b);
		} else if (groupBy.value == "type") {
			return sortByType(a, b);
		} else if (groupBy.value == "cost") {
			return sortByCost(a, b);
		}
	};

	const sortByName = (a, b) => {
		var nameA = a.name.toUpperCase(); // ignore upper and lowercase
		var nameB = b.name.toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	};

	const sortByRGName = (a, b) => {
		var nameA = a.id.substring(a.id.indexOf("/resourceGroups/") + "/resourceGroups/".length, a.id.indexOf("/providers")).toUpperCase(); // ignore upper and lowercase
		var nameB = b.id.substring(b.id.indexOf("/resourceGroups/") + "/resourceGroups/".length, b.id.indexOf("/providers")).toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	};

	const sortByCost = (a, b) => {
		var valA = (Math.round(a.totalCost * 100) / 100).toFixed(2);
		var valB = (Math.round(b.totalCost * 100) / 100).toFixed(2);
		return valB - valA;
	};

	const sortByType = (a, b) => {
		var nameA = a.id.split("/")[a.id.split("/").length - 2].toUpperCase(); // ignore upper and lowercase
		var nameB = b.id.split("/")[b.id.split("/").length - 2].toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	};
	return (
		<>
			<div className="edit-tag-modal">
				<Modal.Content image scrolling>
					<Modal.Description>
						<div className="flex">
							<h3 className="title">
								Cost Allocation for "<div className="icon tag-icon text-icon" style={{backgroundImage: `url(` + tagIcon + `)`}} />
								{props.editTagModalKey} {'" Tag'}
							</h3>
							<div className="edit-tag-button-container">
								<span style={{alignSelf: "center", opacity: ".3", marginRight: "8px"}}>Reset</span>

								<Icon
									name="undo"
									size="big"
									style={{alignSelf: "center", margin: "0px 8px", cursor: "pointer"}}
									onClick={() => {
										reset();
									}}></Icon>
								{deleteMode && (
									<>
										<div className="Toggle-Mode-Button">
											<div className="left " style={{backgroundColor: "rgb(90, 150, 215)"}}>
												<div className="image"></div>
												<i className="image cancel-tag trash alternate icon"></i>
												<div className="tooltip-left">Untag Mode</div>
											</div>
											<div className="right" style={{backgroundColor: "rgb(75, 74, 80)"}}>
												<div className="image" style={{backgroundImage: "url(" + tagIcon + ")", backgroundRepeat: "no-repeat"}}></div>
												<div className="tooltip-right">Tag Mode</div>
											</div>
										</div>

										<Button className="ui button button__component edit-tag-model-button">Save</Button>
									</>
								)}
								{deleteMode == false && (
									<>
										<div className="Toggle-Mode-Button">
											<div className="left " style={{backgroundColor: "rgb(75, 74, 80)"}}>
												<div className="image"></div>
												<i className="image cancel-tag trash alternate icon"></i>
												<div className="tooltip-left">Untag Mode</div>
											</div>
											<div className="right" style={{backgroundColor: "rgb(90, 150, 215)"}}>
												<div className="image" style={{backgroundImage: "url(" + tagIcon + ")", backgroundRepeat: "no-repeat"}}></div>
												<div className="tooltip-right">Tag Mode</div>
											</div>
										</div>
										<Button className="ui button button__component edit-tag-model-button">Save</Button>
									</>
								)}
							</div>
						</div>
						<div className="cost-summary-edit-view">
							{content == "loading" && <Loader />}
							{content == "updating" && (
								<div>
									<h4 className="title" style={{textAlign: "center"}}>
										{deleteMode && <>{"Deleting Tags..."}</>}
										{deleteMode == false && <>{"Updated " + numberOfRequestsCompleted * 12 + " of " + numberOfRequests + " resources..."}</>}
									</h4>
								</div>
							)}
							{content == "syncing" && <h4 className="title">Syncing your latest updates with your cost summary view</h4>}
							{content !== undefined && content !== "loading" && content !== "error" && content !== "updating" && content !== "syncing" && content.values.length > 0 && (
								<>
									<div className="edit-tag-input-holder ui focus input dropdowns" id="cost-allocator-filter">
										<div style={{width: "220px", alignSelf: "flex-end", flexDirection: "column", display: "flex"}}>
											<label for="promo-input">
												<span style={{textAlign: "left", fontSize: "12px", opacity: ".8", color: "white"}}>Filter by resource name</span>
											</label>
											<SubEfficientInput updateFast={updateFast} />
										</div>
										<div id="popup-1">
											{deleteMode == false && (
												<>
													<span className="create-tag-label" style={{width: "360px"}}>
														<label for="react-select-2-input">Values used with "{props.editTagModalKey}" tag</label>
													</span>

													<CreatableSelect
														isClearable
														onMenuOpen={() => {
															props.setDummyMenuActive(true);
														}}
														onMenuClose={() => {
															props.setDummyMenuActive(false);
														}}
														onChange={handleChange}
														onCreateOption={handleCreate}
														options={options.filter((op) => op.value !== "AFP_UNTAGGED" && op.value !== "AFP_EMPTY")}
														value={newAssign}
														className="tag-key-list edit-tag-input"
														placeholder="Type new or select existing"
														styles={dropDownStylesTagEditorValuesDummy}
														theme={(theme) => ({
															...theme,
															borderRadius: 0,
															colors: {
																...theme.colors,
																primary25: "#2684FF"
															}
														})}
													/>
												</>
											)}
										</div>
									</div>
									{editing && (
										<div className="ui equal width center aligned grid ">
											<div className="row extra-padding">
												<div className="column">
													<div style={{display: "flex", justifyContent: "space-between"}}>
														<h4>Resources tagged with other values</h4>
														<div style={{display: "flex", flexDirection: "column"}}>
															<label for="react-select-2-input">
																<span style={{textAlign: "left", fontSize: "12px", opacity: ".8", marginBottom: "4px"}}>Sort By</span>
															</label>
															<Select
																isDisabled={content == "loading"}
																className="tag-dropdown sort-by-options"
																options={groupByOptions}
																value={groupBy}
																onChange={onChangeGroup}
																styles={content == "loading" ? dropdownStylesDashDisabled : dropdownStylesTagEditor}
																placeholder={"Group By"}
																theme={(theme) => ({
																	...theme,
																	borderRadius: 0,
																	colors: {
																		...theme.colors,
																		primary25: "#3376cd"
																	}
																})}
															/>
														</div>
													</div>
												</div>
												<div className="column">
													<div style={{display: "flex", justifyContent: "space-between"}}>
														<h4>Resources tagged with selected value</h4>
														<div style={{display: "flex", flexDirection: "column"}}>
															<label for="react-select-2-input">
																<span style={{textAlign: "left", fontSize: "12px", opacity: ".8", marginBottom: "4px"}}>Sort By</span>
															</label>
															<Select
																isDisabled={content == "loading"}
																className="tag-dropdown sort-by-options"
																options={groupByOptions}
																value={groupBy}
																onChange={onChangeGroup}
																styles={content == "loading" ? dropdownStylesDashDisabled : dropdownStylesTagEditor}
																placeholder={"Group By"}
																theme={(theme) => ({
																	...theme,
																	borderRadius: 0,
																	colors: {
																		...theme.colors,
																		primary25: "#3376cd"
																	}
																})}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}

									{editing == false && (
										<div className="ui equal width center aligned grid ">
											<div className="row extra-padding">
												<div className="column">
													<div style={{display: "flex", justifyContent: "space-between"}}>
														<div style={{display: "flex", flexDirection: "column"}}>
															<label for="react-select-4-input">
																<span style={{textAlign: "left", fontSize: "12px", opacity: ".8", marginBottom: "4px"}}>Sort By</span>
															</label>
															<Select
																isDisabled={content == "loading"}
																className="tag-dropdown sort-by-options"
																options={groupByOptions}
																value={groupBy}
																onChange={onChangeGroup}
																styles={content == "loading" ? dropdownStylesDashDisabled : dropdownStylesTagEditor}
																placeholder={"Group By"}
																theme={(theme) => ({
																	...theme,
																	borderRadius: 0,
																	colors: {
																		...theme.colors,
																		primary25: "#3376cd"
																	}
																})}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}

									{deleteMode && (
										<div className="ui equal width center aligned grid ">
											<div className="row extra-padding">
												<div className="column">
													<h4>Resources that are tagged</h4>
												</div>
												<div className="column">
													<h4>Untagged Resources</h4>
												</div>
											</div>
										</div>
									)}
									<div className={editing || deleteMode ? "ui equal width center aligned padded grid" : ""} style={{height: "500px"}}>
										<div className={editing || deleteMode ? "row" : ""}>
											<div className={editing || deleteMode ? "column" : ""}>
												{liveContent !== undefined && liveContent.values !== undefined && (
													<>
														{liveContent.values
															.filter((value) => {
																if (newAssign !== null) {
																	return value["tag-value"] !== newAssign.value;
																} else {
																	return true;
																}
															})
															.filter((value) => {
																return deleteMode == false || (value["tag-value"] !== "AFP_UNTAGGED" && deleteMode == true);
															})
															.map((value, index) => {
																let valueTotal = 0.0;
																value.resources
																	.filter((res) => {
																		return deleteList.includes(res.id) == false;
																	})
																	.forEach((res) => {
																		Object.entries(res.cost).forEach(([key, Value]) => {
																			valueTotal += parseFloat(Value.substring(1));
																		});
																	});
																let empty = false;
																if (
																	value.resources.filter((res) => {
																		return deleteList.includes(res.id) == false;
																	}).length == 0
																) {
																	empty = true;
																}
																return (
																	<Accordion key={index}>
																		<Accordion.Title active={activeIndexes.includes(index)} index={index} onClick={() => handleClick(index)}>
																			<div className="list-row" id={index === 2 ? "popup-2" : {}}>
																				<div className="icon-and-tag-name-group">
																					<div style={{display: "flex", margin: "0"}}>
																						{deleteMode && value["tag-value"] !== "AFP_UNTAGGED" && empty == false && (
																							<Icon
																								name="x icon centerer large"
																								onClick={(e) => {
																									addAllToDeleteList(e, value);
																								}}
																							/>
																						)}

																						<Icon
																							name={activeIndexes.includes(index) ? "chevron down" : "chevron right"}
																							className={"centerer"}></Icon>
																						{value["tag-value"] !== "AFP_EMPTY" && value["tag-value"] !== "AFP_UNTAGGED" && (
																							<>
																								<div className="icon tag-icon" style={{backgroundImage: `url(` + tagIcon + `)`}} />
																								<span style={{color: "white"}}>{value["tag-value"]}</span>
																							</>
																						)}
																						{(value["tag-value"] == "AFP_EMPTY" || value["tag-value"] == "AFP_UNTAGGED") && (
																							<>
																								<div
																									className="icon tag-icon"
																									style={{
																										backgroundImage: `url(` + tagIcon + `)`,
																										filter: "grayscale(1)"
																									}}
																								/>
																								<span style={{color: "rgb(255 122 80)"}}>
																									{value["tag-value"] == "AFP_EMPTY" && <>{"tagged with no value"}</>}
																									{value["tag-value"] == "AFP_UNTAGGED" && <>{"untagged resources"}</>}
																								</span>
																							</>
																						)}
																					</div>
																					<Icon
																						name="share"
																						className="hoverable-share"
																						style={newAssign ? {} : {visibility: "hidden"}}
																						onClick={(e) => updateAllDescendants(e, value)}></Icon>
																				</div>
																				<div className="tag-cost cost-header">
																					<div className="tag-cost-messaging">Annual Total</div>
																					<p className="tag-figures">
																						{"$"}
																						{(Math.round(valueTotal * 100) / 100).toFixed(2)}
																					</p>
																				</div>
																			</div>
																		</Accordion.Title>
																		<Accordion.Content active={activeIndexes.includes(index)}>
																			{value.resources
																				.filter((res) => {
																					let isNotInCurrentSelected = true;
																					currentlySelected.forEach((currentlySelectedRes) => {
																						if (currentlySelectedRes.id == res.id) {
																							isNotInCurrentSelected = false;
																						}
																					});
																					return isNotInCurrentSelected;
																				})
																				.filter((res) => {
																					return deleteList.includes(res.id) == false && res.name.includes(filterInput);
																				})
																				.sort(sortSwitcher)
																				.map((res, index) => {
																					return (
																						<div
																							className="list-row"
																							key={res.name + "_" + index}
																							onClick={(e) => addToCurrentSelected(e, res, value)}>
																							<div className="icon-and-tag-name-group">
																								<div className="rg-text">
																									<div
																										className="icon rg-icon"
																										style={{backgroundImage: `url(` + "/icons/ic_resource_group.svg" + `)`}}
																									/>
																									<span>
																										{res.id.substring(
																											res.id.indexOf("/resourceGroups/") + "/resourceGroups/".length,
																											res.id.indexOf("/providers")
																										)}
																									</span>
																								</div>
																								{deleteMode && value["tag-value"] !== "AFP_UNTAGGED" && (
																									<Icon
																										name="x icon centerer large"
																										onClick={(e) => {
																											addToDeleteList(e, res);
																										}}
																									/>
																								)}
																								<Icon name="share" style={newAssign ? {} : {display: "none"}}></Icon>

																								<div
																									className="icon resource-icon"
																									style={{backgroundImage: `url(` + checkIfImageExists(res.id) + `)`}}
																								/>
																								<span>
																									<div className="res-name">
																										{res.name.length > 35 ? res.name.slice(0, 35) + "..." : res.name}
																									</div>
																								</span>
																							</div>
																							<div className="tag-cost">
																								<p className="tag-figures">
																									{"$"}
																									{(Math.round(res.totalCost * 100) / 100).toFixed(2)}
																								</p>
																							</div>
																						</div>
																					);
																				})}
																		</Accordion.Content>
																	</Accordion>
																);
															})}
													</>
												)}
											</div>
											{/* Untagged in deleteMode*/}
											{deleteMode == true && liveContent !== undefined && liveContent.values !== undefined && (
												<div className="column" style={{border: "1px dashed rgba(93, 93, 93, 0.75)"}}>
													<>
														{liveContent.values
															.filter((value) => {
																return value["tag-value"] == "AFP_UNTAGGED";
															})
															.map((value, index) => {
																// calculate untagged cost
																let valueTotal = 0.0;
																liveContent.values.forEach((val) => {
																	// get already untagged values
																	if (val["tag-value"] == "AFP_UNTAGGED") {
																		val.resources.forEach((res) => {
																			Object.entries(res.cost).forEach(([key, Value]) => {
																				valueTotal += parseFloat(Value.substring(1));
																			});
																		});
																	} else {
																		// Add new tag values
																		val.resources
																			.filter((res) => {
																				return deleteList.includes(res.id) == true;
																			})
																			.forEach((res) => {
																				Object.entries(res.cost).forEach(([key, Value]) => {
																					valueTotal += parseFloat(Value.substring(1));
																				});
																			});
																	}
																});

																return (
																	<Accordion key={index}>
																		<Accordion.Title active={true} index={index}>
																			<div className="list-row">
																				<div className="icon-and-tag-name-group">
																					<div style={{display: "flex", margin: "0"}}>
																						<Icon name="chevron down" className={"centerer"}></Icon>
																						<div
																							className="icon tag-icon"
																							style={{
																								backgroundImage: `url(` + tagIcon + `)`,
																								filter: "grayscale(1)",
																								opacity: ".2",
																								alignSelf: "center"
																							}}
																						/>
																						<span>untagged resources</span>
																					</div>
																					<div className="tag-cost cost-header">
																						<div className="tag-cost-messaging">Annual Total</div>
																						<p className="tag-figures">
																							{"$"}
																							{(Math.round(valueTotal * 100) / 100).toFixed(2)}
																						</p>
																					</div>
																				</div>
																			</div>
																		</Accordion.Title>
																		<Accordion.Content active={true}>
																			{liveContent.values.map((val) => {
																				return val.resources
																					.filter((res) => deleteList.includes(res.id))
																					.concat(value.resources)
																					.sort(sortSwitcher)
																					.map((res1, index) => {
																						return (
																							<div className="list-row">
																								<div className="icon-and-tag-name-group">
																									<div className="rg-text">
																										<div
																											className="icon rg-icon"
																											style={{backgroundImage: `url(` + "/icons/ic_resource_group.svg" + `)`}}
																										/>
																										<span>
																											{res1.id.substring(
																												res1.id.indexOf("/resourceGroups/") + "/resourceGroups/".length,
																												res1.id.indexOf("/providers")
																											)}
																										</span>
																									</div>
																									<div
																										className="icon resource-icon"
																										style={{
																											backgroundImage: `url(` + checkIfImageExists(res1.id) + `)`
																										}}
																									/>
																									<span>
																										<div className="res-name">
																											{res1.name.length > 35 ? res1.name.slice(0, 35) + "..." : res1.name}
																										</div>
																									</span>
																								</div>
																								<div className="tag-cost">
																									<p className="tag-figures">
																										{"$"}
																										{(Math.round(res1.totalCost * 100) / 100).toFixed(2)}
																									</p>
																								</div>
																							</div>
																						);
																					});
																			})}
																		</Accordion.Content>
																	</Accordion>
																);
															})}
													</>
												</div>
											)}

											{newAssign !== null && newAssign.value !== undefined && newAssign.value !== "" && (
												<div className=" column" style={{border: "1px dashed rgba(93, 93, 93, 0.75)"}}>
													<>
														{liveContent.values && (
															<>
																{liveContent.values
																	.filter((value) => {
																		if (newAssign !== null) {
																			return value["tag-value"] == newAssign.value;
																		} else {
																			return true;
																		}
																	})
																	.map((value, index) => {
																		let valueTotal = 0.0;
																		value.resources.forEach((res) => {
																			Object.entries(res.cost).forEach(([key, Value]) => {
																				valueTotal += parseFloat(Value.substring(1));
																			});
																		});

																		return (
																			<Accordion key={liveContent.values.length + 1}>
																				<Accordion.Title active={true} index={liveContent.values.length + 1}>
																					<div className="list-row edit-view">
																						<div className="icon-and-tag-name-group">
																							<div style={{display: "flex", margin: "0"}}>
																								<Icon name={"chevron down"} className={"centerer"}></Icon>
																								<div className="icon tag-icon" style={{backgroundImage: `url(` + tagIcon + `)`}} />
																								<span>
																									{value["tag-value"]}{" "}
																									<span className="tag-type-designation">
																										{value["new"] ? "- New value" : "- Existing value"}{" "}
																									</span>
																								</span>
																							</div>
																						</div>
																						<div className="tag-cost cost-header">
																							<div className="tag-cost-messaging">Annual Total</div>
																							<p className="tag-figures">
																								{"$"}
																								{(Math.round(NewTotal * 100) / 100).toFixed(2)}
																							</p>
																						</div>
																					</div>
																				</Accordion.Title>
																				<Accordion.Content active={true}>
																					{value.resources.length > 0 && (
																						<>
																							{value.resources
																								.filter((res) => {
																									return res.name.includes(filterInput);
																								})
																								.sort(sortSwitcher)

																								.map((res, index) => {
																									return (
																										<div className="list-row  edit-view-row" key={index}>
																											<div className="icon-and-tag-name-group">
																												<div className="rg-text">
																													<div
																														className="icon rg-icon"
																														style={{
																															backgroundImage:
																																`url(` + "/icons/ic_resource_group.svg" + `)`
																														}}
																													/>
																													<span>
																														{res.id.substring(
																															res.id.indexOf("/resourceGroups/") +
																																"/resourceGroups/".length,
																															res.id.indexOf("/providers")
																														)}
																													</span>
																												</div>
																												<div
																													className="icon resource-icon"
																													style={{
																														backgroundImage: `url(` + checkIfImageExists(res.id) + `)`
																													}}
																												/>
																												<span>
																													<div className="res-name">
																														{res.name.length > 35
																															? res.name.slice(0, 35) + "..."
																															: res.name}
																													</div>
																												</span>
																											</div>
																											<div className="tag-cost">
																												<p className="tag-figures">
																													{"$"}
																													{(Math.round(res.totalCost * 100) / 100).toFixed(2)}
																												</p>
																											</div>
																										</div>
																									);
																								})}
																						</>
																					)}
																				</Accordion.Content>
																			</Accordion>
																		);
																	})}
															</>
														)}
													</>
												</div>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</Modal.Description>
				</Modal.Content>
			</div>
		</>
	);
};

export default EditTagModal;
