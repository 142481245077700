import {Icon} from "semantic-ui-react";
import React, {useContext} from "react";
import "./ToastifyMessage.css";
import AppContext from "../../../../utils/AppContext";
const ToastifyMessage = (props) => {
	let subName = "";
	const context = useContext(AppContext);
	try {
		subName = context.state.elevatedSubscriptionNames[context.state.elevatedSubscriptions.indexOf(props.sub)];
	} catch {
		subName = props.sub;
	}

	return (
		<div style={{fontSize: "11px"}}>
			<Icon onClick name="info" size="large" className="info-icon" />
			Tags updated for {subName}
			<button className=" ui green inverted button mini" style={{marginLeft: "8px"}} onClick={() => props.toggleDiffModal(props.res)}>
				Update Breakdown
			</button>
		</div>
	);
};

export default ToastifyMessage;
