import React, {useContext} from "react";
import {Link} from "react-router-dom";
import AppContext from "../../../utils/AppContext";
import TopMenu from "components/page-layout/TopMenu";
import moment from "moment";
import "./CommonPageLayout.css";


const CommonPageLayoutFluid = ({className, pageName, fullwidth, children, activeTab}) => {
	const context = useContext(AppContext);
	return (
		<>
			{context.state.accountType == "Free" && (
				<div className="freebar">
					<p>
						You are currently using the free version of CloudClarity. Click <Link to={"/pricing"}>here</Link> to upgrade
					</p>
				</div>
			)}
			{context.state.elevatedSubscriptions.length !== 0 && context.state.accountType == "Trial" && (
				<div className="freebar">
					<p>You are currently using the trial version of CloudClarity. Your Trial will expire {moment(context.state.expiryDate, "MM/DD/YYYY hh:mm:ss A").fromNow()}</p>
				</div>
			)}
			<div className={`common-page-layout__component-alt`}>
				<div className="top-menu-container">
					<TopMenu activeTab={activeTab} />
				</div>
			</div>
		</>
	);
};

export default CommonPageLayoutFluid;
